<template>
  <div :class="{ 'q-pa-md': mobile }">
    <QInput
      v-bind="$attrs"
      :model-value="modelValue ? modelValue.value : null"
      :label="t('Value')"
      dense
      bottom-slots
      :type="operator.inputType ?? 'search'"
      :suffix="operator.valueSuffix"
      @update:model-value="onInput($event)"
      @keypress.enter="emit('save-filter')"
    />
    <ButtonsRow
      v-if="mobile && modelValue"
      v-slot="{ buttonProps }"
      v2
    >
      <QBtn
        v-bind="buttonProps"
        icon="mdi-content-save"
        @click="emit('save-filter')"
      >
        {{ t('Apply') }}
      </QBtn>
    </ButtonsRow>
  </div>
</template>

<script setup lang="ts">

import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import type { FilterOperatorCommon, FilterValue } from '@/types/reports';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  modelValue: FilterValue<string> | null;
  operator: FilterOperatorCommon;
  mobile: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: FilterValue<string> | null): void;
  (e: 'save-filter'): void;
}>();

function onInput(newValue: string): void {
  const label = newValue + (props.operator.valueSuffix ? ` ${props.operator.valueSuffix}` : '');

  emit('update:modelValue', newValue ? {
    label,
    value: newValue,
  } as FilterValue<string> : null);
}

</script>

<i18n lang="yaml">
ru:
  Value: Значение
en:
  Value: Value
</i18n>
