<template>
  <MaximizedDialog
    :model-value="modelValue"
    transition-show="slide-up"
    transition-hide="slide-down"
    :title="t('Task')"
    @close="emit('update:modelValue', false)"
  >
    <QList>
      <QVirtualScroll
        v-slot="{item}"
        :items="transfer.task!.items"
      >
        <ProductListItem
          :key="item.id"
          :product="item.storageUnit.productPack.product"
          clickable
          hide-empty-photo
          @click="editingProductId = item.storageUnit.productPack.product.id"
        >
          <template #top-right>
            <AmountCompletionChip
              :product-pack="item.storageUnit.productPack"
              :expected-amount="item.plannedAmount"
              :actual-amount="item.transferredAmount"
            />
          </template>
        </ProductListItem>
      </QVirtualScroll>
    </QList>

    <template
      v-if="$slots['buttons']"
      #bottom
    >
      <ButtonsRow
        v-slot="{ buttonProps }"
        v2
      >
        <slot
          name="buttons"
          v-bind="{ buttonProps }"
        />
      </ButtonsRow>
    </template>

    <ProductEditDialog
      v-if="editingProductId"
      :id="editingProductId"
      @cancel="editingProductId = null"
    />
  </MaximizedDialog>
</template>

<script setup lang="ts">

import type { Scalars, Transfer } from '@/graphql/types';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import ProductEditDialog from '@/views/Mobile/ProductEditDialog.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ProductListItem from '@/components/ProductListItem.vue';
import AmountCompletionChip from '@/components/Mobile/AmountCompletionChip.vue';

const { t } = useI18n();

defineProps<{
  modelValue: boolean;
  transfer: Transfer;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const editingProductId = ref<Scalars['ID'] | null>(null);

</script>
