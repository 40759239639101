<!-- По большей части компонент повторяет SelectionEditProductAmountDialog -->
<template>
  <QDialog
    v-model="isOpen"
    :persistent="saving"
    :position="preferences.usePhysicalKeyboard ? undefined : 'bottom'"
  >
    <QCard class="column no-wrap">
      <QCardSection>
        <QInput
          :model-value="inputValue"
          readonly
          :label="t('Amount Taken')"
        />
      </QCardSection>
      <PrimaryErrorBanner />
      <QCardActions align="right">
        <QBtn
          :label="t('Cancel')"
          :disable="saving"
          @click="isOpen = false"
        />
        <QBtn
          :label="t('Save')"
          color="primary"
          :loading="saving"
          @click="changeAmount"
        />
      </QCardActions>
      <div>
        <VirtualKeyboard
          local
          :show-comma-key="amountIsFractional"
          show-enter-key
          @enter="changeAmount"
        />
        <BlurredInput :filter="blurredInputFilter" />
      </div>
    </QCard>
  </QDialog>

  <slot
    name="activator"
    @click="isOpen = true"
  />
</template>

<script setup lang="ts">

import useErrorHandling from '@/composables/useErrorHandling';
import useLocalPreferences from '@/composables/useLocalPreferences';
import useOmniInput from '@/composables/useOmniInput';
import type { Movement } from '@/graphql/types';
import useTransferProcessStore from '@/stores/transferProcess';
import { replace } from 'ramda';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const store = useTransferProcessStore();

const emit = defineEmits<{
  (e: 'amount-taken'): void;
}>();

const { fillErrorsFromGraphQLError, PrimaryErrorBanner, clearErrors } = useErrorHandling();

const preferences = useLocalPreferences();

const isOpen = ref(false);

const saving = ref(false);

const movement = computed(() => store.transfer!.movements
  ?.find(m => m.storable.id === store.currentItem?.storageUnit.id
        && m.storageFrom!.id === store.storageFrom?.id
        && m.storageTo!.id === store.storageTo?.id) ?? null);

const input = useOmniInput({
  skip: computed(() => !isOpen.value),
  replace: replace(/,/g, '.'),
});
const { VirtualKeyboard, BlurredInput, value: inputValue } = input;

watch(isOpen, open => {
  if (open) {
    input.reset(String(movement.value?.amount ?? 0));
  }
});

async function changeAmount(): Promise<void> {
  clearErrors();

  saving.value = true;

  const { error } = await saveMovement(movement.value, Number(inputValue.value));

  if (error) {
    fillErrorsFromGraphQLError(error);
    saving.value = false;
    return;
  }

  saving.value = false;
  isOpen.value = false;

  emit('amount-taken');
}

async function saveMovement(movement: Movement | null, amount: number) {
  if (movement) {
    return store.updateMovementAmount({ movement, amount });
  }

  if (amount === 0) {
    return { error: null };
  }

  return store.moveStorageUnit(store.currentItem!.storageUnit, amount);
}

const amountIsFractional = computed(() => store.currentItem?.storageUnit.productPack.measurementUnit.isFractional);

function blurredInputFilter(key: string) {
  return amountIsFractional.value
    ? /^[\d,.]$/.test(key)
    : /^\d$/.test(key);
}

</script>

<i18n lang="yaml">
ru:
  Amount Taken: Взято товара

en:
  Amount Taken: Amount Taken

</i18n>
