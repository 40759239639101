<template>
  <div class="col justify-center">
    <ProductPackWithScannedAmount
      :product-pack="productPack"
      :scanned-amount="scannedAmount"
      :needed-amount="neededScannedAmount"
      hide-reset-button
      @photo-click="photosZoomed = $emit"
    />
    <div class="q-pa-lg justify-center">
      <BaseScanField
        :hint="t('Scan and take needed Product')"
        :search-fn="searchFn"
        :placeholder="t('Product')"
        :not-found-message="t('Wrong Product')"
        :rules="rules"
        :disabled="disabled"
        @scan="emit('scan', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">

import type { Container, ProductPack, StorageUnit } from '@/graphql/types';
import { useI18n } from 'vue-i18n';
import type { FunctionValidationRule } from '@/types';
import { ref } from 'vue';
import BaseScanField from '@/components/Mobile/BaseScanField.vue';
import ProductPackWithScannedAmount from '@/views/Mobile/ProductPackWithScannedAmount.vue';

const { t } = useI18n();

defineProps<{
  productPack: ProductPack;
  availableAmount?: number;
  neededScannedAmount: number;
  rules?: FunctionValidationRule[];
  disabled?: boolean;
  scannedAmount?: number;
  searchFn: ((barcode: string | null) => object);
}>();

const emit = defineEmits<{
  (e: 'scan', storable: StorageUnit | Container): void;
}>();

const photosZoomed = ref(false);

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/selection.yaml"></i18n>

<style scoped lang="scss">

.width-transition {
  transition: width $animate-duration ease;
}

</style>
