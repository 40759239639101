<template>
  <BigLoading v-if="fetching" />
  <template v-else>
    <QTabs
      v-model="selectedTab"
      mobile-arrows
      align="left"
      :breakpoint="0"
    >
      <QTab
        v-for="pack in packs"
        :key="pack.id"
        :name="pack.id"
        :label="pack.measurementUnit.name"
        no-caps
      >
        <template #default>
          <span
            v-if="pack.smallerProductPack"
            class="absolute-top text-left text-caption text-grey"
          >
            {{ pack.quantity }} {{ pack.smallerProductPack.measurementUnit.shortName }}
          </span>
        </template>
      </QTab>
      <QTab
        name="new"
        class="q-px-none"
      >
        <template #default>
          <QBtn
            flat
            round
            icon="mdi-plus-circle"
            dense
            color="success"
            :title="t('Create Product Pack')"
          />
        </template>
      </QTab>
    </QTabs>
    <QTabPanels
      v-model="selectedTab"
      animated
    >
      <QTabPanel
        v-for="pack in packs"
        :key="pack.id"
        :name="pack.id"
        class="q-pa-none"
      >
        <ProductPackEdit
          :product-pack="pack"
          hide-details
          hide-photo
          :buttons-panel="buttonsPanel"
          @save="handleSave"
          @goto-product="emit('goto-product', $event)"
        >
          <template #left-buttons="{ buttonProps }">
            <slot
              name="left-buttons"
              :button-props="buttonProps"
            />
          </template>
        </ProductPackEdit>
      </QTabPanel>
      <QTabPanel
        name="new"
        class="q-pa-none"
      >
        <NewProductPack
          :product="product"
          :product-packs="packs"
          :buttons-panel="buttonsPanel"
          @created="handleSave"
          @cancel="selectedTab = packs?.[0].id ?? null"
        />
      </QTabPanel>
    </QTabPanels>
  </template>
</template>

<script setup lang="ts">

import BigLoading from '@/components/BigLoading.vue';
import NewProductPack from '@/components/Mobile/NewProductPack.vue';
import ProductPackEdit from '@/components/Mobile/ProductPackEdit.vue';
import ProductPackForMobileCard from '@/graphql/fragments/ProductPackForMobileCard';
import { MeasurementUnitClassEnum } from '@/graphql/types';
import type { Product, ProductPack, QueryProductArgs } from '@/graphql/types';
import type { MaybeID } from '@/types';
import { gql, useClientHandle } from '@urql/vue';
import { sortBy } from 'ramda';
import { onBeforeMount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { client: urql } = useClientHandle();

const props = defineProps<{
  product: Product;
  buttonsPanel?: HTMLElement | string;
}>();

const emit = defineEmits<{
  (e: 'goto-product', id: string): void;
}>();

const packs = ref<ProductPack[] | null>(null);

const fetching = ref(false);

onBeforeMount(loadPacks);

async function loadPacks() {
  fetching.value = true;

  const { data } = await urql.query<{ product: Product }, QueryProductArgs>(
    gql`
      ${ProductPackForMobileCard}
      query GetProductForProductPacksEdit($id: ID!) {
        product(id: $id) {
          id
          productPacks {
            ...ProductPackForMobileCard
          }
        }
      }
    `,
    { id: props.product.id }
  );

  fetching.value = false;

  packs.value = sortBy(p => [
    MeasurementUnitClassEnum.PIECES,
    MeasurementUnitClassEnum.BOXES,
    MeasurementUnitClassEnum.PALLETS,
  ].indexOf(p.measurementUnit.class), data!.product.productPacks);
}

const selectedTab = ref<string | null>(null);

const lastEditedPackId = ref<MaybeID | null>(null);

watch(packs, packs => {
  if (lastEditedPackId.value) {
    selectedTab.value = lastEditedPackId.value;
    lastEditedPackId.value = null;
  } else {
    selectedTab.value = packs?.[0].id ?? null;
  }
});

function handleSave(pack: ProductPack) {
  const packToUpdate = packs.value?.find(p => p.id === pack.id);
  if (packToUpdate) {
    Object.assign(packToUpdate, pack);
  } else {
    packs.value!.push(pack);
    packs.value = sortBy(p => [
      MeasurementUnitClassEnum.PIECES,
      MeasurementUnitClassEnum.BOXES,
      MeasurementUnitClassEnum.PALLETS,
    ].indexOf(p.measurementUnit.class), packs.value!);
  }
  lastEditedPackId.value = pack.id;
}

</script>

<i18n lang="yaml">
ru:
  Create Product Pack: Создать упаковку
en:
  Create Product Pack: Create Product Pack
</i18n>
