ru:
  Cancel Selection: Отменить отбор
  Cancel Selection?: Отменить отбор?
  Cluster Selection: Кластерный отбор
  Complete Selection: Завершить отбор
  Complete Selection?: Завершить отбор?
  Group Selection: Групповой отбор
  Next: Следующий
  Scan the above specified Container: Отсканируйте указанный выше контейнер
  Selection by Shipment Order: Позаказный отбор
  Selection Plan: План отбора
  Selection: Отбор
  Wrong Container: Не тот контейнер
  Excess product, only {needed} needed: Лишний товар, надо {needed}

en:
  Cancel Selection: Cancel Selection
  Cancel Selection?: Cancel Selection?
  Cluster Selection: Cluster Selection
  Complete Selection: Complete Selection
  Complete Selection?: Complete Selection?
  Group Selection: Group Selection
  Next: Next
  Scan the above specified Container: Scan the above specified Container
  Selection by Shipment Order: Selection by Shipment Order
  Selection Plan: Selection Plan
  Selection: Selection
  Wrong Container: Wrong Container
  Excess product, only {needed} needed: Excess product, only {needed} needed
