<template>
  <VirtualKeyboardPolicyContext #="{ policy, toggle, shouldShowToggle }">
    <QInput
      :ref="e => { field = e?.getNativeElement(); }"
      v-bind="$attrs"
      v-model="modelValue"
      :error="error !== null"
      :error-message="error"
      :loading="isLoading"
      :disable="isLoading || disabled"
      dense
      autocomplete="off"
      :virtualkeyboardpolicy="policy"
      @keypress.enter="scan($event.target.value)"
      @focus="($event.target as QInput | null)?.select()"
    >
      <template #prepend>
        <QIcon name="mdi-barcode" />
      </template>
      <template
        v-if="shouldShowToggle || showSubmitButton"
        #append
      >
        <QBtn
          v-if="shouldShowToggle"
          flat
          round
          icon="mdi-keyboard"
          @click="toggle(); field.focus();"
        />
        <QBtn
          v-if="showSubmitButton"
          icon="mdi-plus"
          :label="t('Add')"
          color="success"
          @click="scan(modelValue)"
        />
      </template>
    </QInput>
    <BlurredInput
      v-if="!noOmniInputScan"
      @barcode="handleBarcodeInput"
    />
  </VirtualKeyboardPolicyContext>
</template>

<script setup lang="ts" generic="TEntity">

import useOmniInput from '@/composables/useOmniInput';
import useSpeaker from '@/composables/useSpeaker';
import { VirtualKeyboardPolicyContext } from '@/composables/useVirtualKeyboardPolicy';
import type { FunctionValidationRule } from '@/types';
import { QInput } from 'quasar';
import type { Promisable } from 'type-fest';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const speaker = useSpeaker();

const fetching = ref(false);

const isLoading = computed(() => props.loading || fetching.value);

const { BlurredInput } = useOmniInput({
  skip: computed(() => isLoading.value || props.disabled || props.noOmniInputScan),
});

function handleBarcodeInput(value: string) {
  modelValue.value = value;
  scan(value);
}

const props = withDefaults(defineProps<{
  notFoundMessage?: string;
  barcodeRules?: FunctionValidationRule<string>[];
  rules?: FunctionValidationRule<TEntity>[];
  loading?: boolean;
  disabled?: boolean;
  noOmniInputScan?: boolean;
  initialValue?: string;
  searchFn: (barcode: string) => Promisable<TEntity | null>;
  showSubmitButton?: boolean;
}>(), {
  barcodeRules: () => ([]),
  rules: () => ([]),
});

const modelValue = ref(props.initialValue);

const emit = defineEmits<{
  (e: 'update:scanning', value: boolean): void;
  (e: 'scan', entity: TEntity | null, barcode: string): void;
  (e: 'not-found', barcode: string): void;
}>();

const error = ref<string | null>(null);

watch(error, newError => {
  if (newError) {
    speaker.speak(newError);
  }
});


async function scan(barcode?: string) {
  if (!barcode) {
    return;
  }

  const barcodeError = props.barcodeRules
    .map(rule => rule(barcode))
    .find((result): result is string => result !== true);

  if (barcodeError) {
    error.value = barcodeError;
    return;
  }

  error.value = null;

  fetching.value = true;
  let newEntity: TEntity | null = null;
  try {
    newEntity = await props.searchFn(barcode);
  } catch (e) {
    error.value = (e as Error).message;
    fetching.value = false;
    return;
  }

  if (newEntity === null) {
    emit('not-found', barcode!);
    if (props.notFoundMessage) {
      error.value = props.notFoundMessage;
    }
    fetching.value = false;
    return;
  }

  const validationError = await validateResult(newEntity);

  fetching.value = false;

  if (validationError) {
    error.value = validationError;
    return;
  }

  modelValue.value = '';

  emit('scan', newEntity, barcode);
}

async function validateResult(entity: TEntity): Promise<string | null> {
  for (const rule of props.rules) {
    const result = await rule(entity);
    if (result !== true) {
      return result;
    }
  }

  return null;
}

const field = ref();

watch(fetching, fetching => {
  emit('update:scanning', fetching);
});

defineExpose({ scan });

</script>
