<template>
  <div
    v-clamp="3"
    class="text-h5 q-px-md"
  >
    {{ props.productPack.product.name }}
    <template v-if="props.productPack?.quantityInMinMeasurementUnits > 1">
      ({{ props.productPack.measurementUnit.name }})
    </template>
  </div>
  <div class="q-px-md row no-wrap justify-between items-center">
    <div>
      <div class="text-grey">
        {{ t('SKU') }}
      </div>
      {{ props.productPack.product.sku }}
    </div>
    <div class="text-h4">
      <ConfirmsAction
        v-if="!hideResetButton"
        :confirm-text="t('Reset')"
        :cancel-text="t('No')"
        @confirmed="emit('reset-amount')"
      >
        <template #activator="{ prompt }">
          <QBtn
            color="red"
            icon="mdi-delete"
            dense
            flat
            @click="prompt"
          />
        </template>

        <template #title>
          {{ t('Reset amount?') }}
        </template>
      </ConfirmsAction>
      {{ scannedAmount }} /
      {{ neededAmount }}
    </div>
  </div>
  <div
    v-if="props.productPack?.product.photos.length > 0"
    class="text-center"
  >
    <div
      class="inline-block width-transition"
      :style="{ width: photosZoomed ? '100%' : '50%' }"
      @click="photoClick"
    >
      <ProductPhotosMobile
        :product="props.productPack?.product"
      />
    </div>
  </div>
</template>

<script setup lang="ts">

import type { ProductPack } from '@/graphql/types';
import ProductPhotosMobile from '@/views/Products/ProductEdit/ProductPhotosMobile.vue';
import { useI18n } from 'vue-i18n';
import vClamp from '@/directives/clamp';
import ConfirmsAction from '@/components/ConfirmsAction.vue';

const { t } = useI18n();

const props = defineProps<{
  productPack: ProductPack;
  scannedAmount?: number;
  neededAmount?: number;
  photosZoomed?: boolean;
  hideResetButton?: boolean;
}>();

const emit = defineEmits<{
  (e: 'photo-click', photosZoomed: boolean): void;
  (e: 'reset-amount'): void;
}>();

function photoClick(): void {
  emit('photo-click', !props.photosZoomed);
}

</script>

<i18n lang="yaml">
ru:
  Reset: Удалить
  Reset amount?: Удалить сканирование товара?

en:
  Reset: Reset
  Reset amount?: Reset scanned product?

</i18n>
