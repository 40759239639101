<template>
  <div class="q-pb-md">
    <HorizontalPropsList v-slot="{ item }">
      <component
        :is="item"
        v-if="store.currentSelection"
        :caption="t('Orders')"
      >
        <SliderPropWithExpansion
          v-model:expanded="isOrdersExpanded"
          :items="shipmentsExternalIds"
        />
      </component>
      <component
        :is="item"
        v-if="store.currentStorage"
        :caption="t('Storage')"
      >
        {{ store.currentStorage.name }}
      </component>
      <component
        :is="item"
        v-if="store.container"
        :caption="t('Container')"
      >
        {{ store.container.name }}
      </component>
      <component
        :is="item"
        v-if="store.currentSelection"
        :caption="t('Cells Count')"
      >
        {{ scannedCellsCount }} / {{ neededCellsCount }}
      </component>
      <component
        :is="item"
        v-if="store.currentSelection"
        :caption="t('Volume')"
        class="text-no-wrap"
      >
        {{ formatVolume(scannedVolume) }} / {{ formatVolume(volume) }}
      </component>
      <component
        :is="item"
        v-if="currentPack"
        :caption="t('Product barcode')"
      >
        <SliderPropWithExpansion
          v-model:expanded="isBarcodesExpanded"
          :items="currentPackBarcodes"
        />
      </component>

      <component
        :is="item"
        v-if="store.currentSelection"
        :caption="t('Selection')"
      >
        {{ store.currentSelection.id }}
      </component>
    </HorizontalPropsList>
    <BaseAlert
      v-if="notAvailableOrders.length > 0"
      type="info"
      dismissable
    >
      {{
        t('There are not enough products for {orders} orders. Selection will be cancelled.',
          { orders: notAvailableOrders.map(so => so.externalId).join(',') },
          notAvailableOrders.length
        )
      }}
    </BaseAlert>
  </div>
</template>

<script setup lang="ts">

import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import { calculateVolumeForShipment } from '@/helpers/shipments';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import useSelectionStore from '@/stores/selection';
import HorizontalPropsList from '@/views/Mobile/HorizontalPropsList.vue';
import * as R from 'ramda';
import { sortBy } from 'ramda';
import { ShipmentStateEnum } from '@/graphql/types';
import calculateVolume from '@/helpers/calculateVolume';
import BaseAlert from '@/components/BaseAlert.vue';
import SliderPropWithExpansion from '@/views/Mobile/SliderPropWithExpansion.vue';

const { t } = useI18n();

const { formatVolume } = useLocalizedFormatters();

const store = useSelectionStore();

const volume = computed(() => R.sum(
  store.currentSelection!.selectionOrders
    .map(so => calculateVolumeForShipment(so.shipment)),
));

const scannedVolume = computed(() => R.sum(
  store.currentSelection!.movements
    .map(m => calculateVolume(m.storable.productPack.dimensions) * m.amount),
));

const scannedCellsCount = computed(() => R.uniqBy(
  c => c.id,
  store.scannedCells.filter(c => c!.id !== store.currentStorage?.id),
  // Движение создается только после сканирования товара,
  // но надо учесть текущую отсканированную ячейку
).length + (store.currentStorage ? 1 : 0));

const neededCellsCount = computed(() => store.cells.length);

const notAvailableOrders = computed(() => (store.currentSelection?.selectionOrders ?? [])
  .map(selectionOrder => selectionOrder.shipment)
  .filter(shipment => shipment.state === ShipmentStateEnum.CREATED));

const currentPack = computed(() => store.selectedItem?.selectionOrderItem.storageUnit.productPack ?? null);

// Сортируем так, чтобы основной ШК был первым.
const currentPackBarcodes = computed(() => sortBy(
  b => Number(b.barcode !== currentPack.value?.lastScannedBarcode?.barcode),
  currentPack.value?.barcodes ?? [],
).map(b => b.barcode));

const shipmentsExternalIds = computed(() => store.currentSelection?.shipments.map(s => s.externalId));

const isOrdersExpanded   = ref(false);
const isBarcodesExpanded = ref(false);

watch(() => store.selectedItem, () => {
  isBarcodesExpanded.value = false;
  isOrdersExpanded.value   = false;
});

</script>

<i18n lang="yaml">
ru:
  Volume: Объем
  Cells Count: Ячеек
  Product barcode: ШК товара
  "{n} more": "еще {n}"
  hide: скрыть
  There are not enough products for {orders} orders. Selection will be cancelled.: >
    Для заказов {orders} недостаточно товаров. Отбор для них будет отменен.
    | Для заказа {orders} недостаточно товаров. Отбор для него будет отменен.
    | Для заказов {orders} недостаточно товаров. Отбор для них будет отменен.
    | Для заказов {orders} недостаточно товаров. Отбор для них будет отменен.

en:
  Volume: Volume
  Cells Count: Cells
  Product barcode: Product barcode
  "{n} more": "{n} more"
  hide: hide
  There are not enough products for {orders} orders. Selection will be cancelled.: >
    There are not enough products for {orders} orders. Selection will be cancelled.
    | There are not enough products for {orders} orders. Selection will be cancelled.

</i18n>
