<template>
  <ProductSlide
    :product-pack="item.selectionOrderItem.storageUnit.productPack"
    :actual-amount="displayAmount"
    :expected-amount="item.selectionOrderItem.plannedAmount * item.selectionOrderItem.storageUnit.productPack.quantityInMinMeasurementUnits"
    :amount-badge-color="productSlideAmountBadgeColor"
    title-clickable
    @delete="emit('delete')"
    @title-click="emit('product-click', item.selectionOrderItem.storageUnit.productPack.product)"
    @amount-click="handleAmountClick"
  >
    <template
      v-if="store.containers.length > 1"
      #bottom
    >
      <QItem>
        <QItemSection>
          {{ t('Order') }}
          {{ item.selectionOrder.shipment.externalId }}
        </QItemSection>
        <QItemSection class="text-right">
          {{ item.selectionOrder.container.name }}
        </QItemSection>
      </QItem>
    </template>
    <template
      v-if="indicesWithProduct.length > 1"
      #under-amount
    >
      <CarouselIndicators
        :count="indicesWithProduct.length"
        :active="indicesWithProduct.indexOf(index)"
        :limit="5"
        :indicator-size="7"
      />
    </template>
  </ProductSlide>
</template>

<script setup lang="ts">

import CarouselIndicators from '@/components/Mobile/CarouselIndicators.vue';
import ProductSlide from '@/components/Mobile/ProductSlide.vue';
import type { Product } from '@/graphql/types';
import useSelectionStore from '@/stores/selection';
import type { SelectionCarouselItem } from '@/types/selection';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const store = useSelectionStore();

const props = defineProps<{
  item: SelectionCarouselItem;
  index: number;
  displayAmount: number | string;
}>();

const emit = defineEmits<{
  (e: 'delete'): void;
  (e: 'product-click', product: Product): void;
  (e: 'update:slide', slide: number): void;
}>();

const productSlideAmountBadgeColor = computed((): string | undefined => {
  if (props.item.selectionOrderItem.selectedAmount === props.item.selectionOrderItem.plannedAmount) {
    return 'green-3';
  }

  if (props.item.selectionOrderItem.selectedAmount > 0) {
    return 'yellow-3';
  }

  return undefined;
});

// Не используем сами элементы по аналогии с другими операциями,
// так как в списке один элемент может встречаться два раза (в плане и в истории).
const indicesWithProduct = computed(() => store.carouselItems
  .map((item, index) => [item, index] as const)
  .filter(([item]) => item.selectionOrderItem.storageUnit.productPack.product.id
    === props.item.selectionOrderItem.storageUnit.productPack.product.id)
  .map(([, index]) => index));

function handleAmountClick() {
  const index = (indicesWithProduct.value.indexOf(props.index) + 1) % indicesWithProduct.value.length;

  emit('update:slide', (indicesWithProduct.value)[index]);
}

</script>

