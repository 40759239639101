<template>
  <div class="q-pa-lg">
    <GraphQLQueryScanField
      ref="scanField"
      :query="productPackQuery"
      :optimistic-result="barcode => productsByBarcodes[barcode] ?? null"
      :hint="t('Scan and take needed Product')"
      :placeholder="t('Product')"
      :not-found-message="t('Product not found')"
      no-omni-input-scan
      @scan="emit('scan', $event)"
      @scanning="$event && emitCloseDialogs()"
    />
  </div>
</template>

<script setup lang="ts">

import GraphQLQueryScanField from '@/components/Mobile/GraphQLQueryScanField.vue';
import ProductPackForInventory from '@/graphql/fragments/ProductPackForInventory';
import type { ProductPackWithAmount } from '@/graphql/types';
import useProductArrivalStore from '@/stores/productArrival';
import { gql } from '@urql/vue';
import { useEventBus } from '@vueuse/core';
import { onBeforeMount, reactive, type Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emit = defineEmits<{
  (e: 'scan', pack: ProductPackWithAmount): void;
}>();

const store = useProductArrivalStore();

const { emit: emitCloseDialogs } = useEventBus('close-dialogs');

const productPackQuery = gql`
  ${ProductPackForInventory}
  query GetProductPackByBarcodeForArrival($barcode: String!) {
    productPackByBarcode(barcode: $barcode) {
      productPack {
        ...ProductPackForInventory
      }
      amount
    }
  }
`;

const productsByBarcodes = reactive<Record<string, ProductPackWithAmount>>({});

onBeforeMount(() => {
  if (!store.supply) {
    return;
  }

  for (const item of store.supply.items) {
    for (const barcode of item.productPack.barcodes) {
      productsByBarcodes[barcode.barcode] = { productPack: item.productPack, amount: 1 };
    }
  }
});

const scanField: Ref<InstanceType<typeof GraphQLQueryScanField>> = ref(null!);

defineExpose({
  scan: (value: string) => scanField?.value.scan(value),
});

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml"></i18n>
