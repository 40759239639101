<template>
  <BigLoading v-if="progress.initializing" />
  <QPage
    v-else
    class="column no-wrap scroll-y"
  >
    <PrimaryErrorBanner animated />
    <SelectionHeader />
    <template v-if="currentSelection && store.shouldScanContainer">
      <div class="col justify-top">
        <div class="row justify-center q-pa-mb">
          <h5 class="q-ma-none">
            {{ expectedContainer.name }}
          </h5>
        </div>
      </div>
      <div class="col q-pa-lg justify-top">
        <GraphQLQueryScanField
          :query="containerQuery"
          :placeholder="t('Container')"
          :not-found-message="t('Container not found')"
          :hint="t('Scan the above specified Container')"
          :rules="[containerMatchesSelectionRule]"
          @scan="store.container = $event"
        />
      </div>
    </template>
    <template v-else-if="!store.fullySelected">
      <div
        v-if="!currentStorage"
        class="col justify-top"
      >
        <div class="row justify-center q-pa-mb">
          <h5 class="q-ma-none">
            {{ expectedStorage.name }}
            <template v-if="isContainer(expectedStorage)">
              ({{ expectedStorage.storageCell.name }})
            </template>
          </h5>
        </div>
      </div>
      <div
        v-if="!currentStorage"
        class="col justify-center"
      >
        <div class="q-pa-lg">
          <GraphQLQueryScanField
            :query="storageQuery"
            :hint="t('Scan the above specified Storage')"
            :placeholder="t('Storage')"
            :not-found-message="t('Storage not found')"
            :rules="[cellMatchesSelectionRule]"
            @scan="currentStorage = $event"
          />
        </div>
      </div>
      <ScanPack
        v-else-if="currentSelection"
        ref="scanPackRef"
        :current-amount="inputValue || '0'"
        @delete-selected="handleDeleteItem"
        @before-scan="handleBeforeProductScan"
        @scan="handleProductScan"
      />
    </template>
    <Teleport
      to="#teleport-target-buttons-row"
    >
      <ButtonsRow
        v-slot="{ buttonProps }"
        v2
      >
        <KeyboardToggleButton
          v-if="store.currentStorage && !store.shouldScanContainer"
          v-bind="buttonProps"
          :disable="!store.canChangeAmount"
        />
        <QBtn
          v-if="currentSelection"
          v-bind="buttonProps"
          icon="mdi-file-tree-outline"
          @click="selectionPlanOpen = true"
        >
          {{ t('Selection Plan') }}
        </QBtn>
        <MaximizedDialog
          :model-value="selectionPlanOpen"
          transition-show="slide-up"
          transition-hide="slide-down"
          :title="t('Selection Plan')"
          @close="closeSelectionPlan"
        >
          <SelectionDetailsForDashboard
            :id="currentSelection.id"
            :cell-id="currentStorage?.id"
            items-selectable
            @update:selected-item="selectedPlanItem = $event"
          >
            <template #buttons="{ buttonProps: innerButtonProps, currentCell }">
              <SelectionChangeCellButton
                v-bind="innerButtonProps"
                :cell="currentCell"
                @change="selectionPlanOpen = false"
              />
            </template>
          </SelectionDetailsForDashboard>
        </MaximizedDialog>
        <ConfirmsAction
          v-if="currentSelection?.movements.length === 0"
          should-prompt
          :confirm-text="t('Cancel Selection')"
          :cancel-text="t('No')"
          @confirmed="cancelSelection"
        >
          <template #activator="{ prompt }">
            <QBtn
              v-bind="buttonProps"
              icon="mdi-close-circle-outline"
              @click="prompt"
            >
              {{ t('Cancel Selection') }}
            </QBtn>
          </template>

          <template #title>
            {{ t('Cancel Selection?') }}
          </template>
        </ConfirmsAction>
        <SelectionEditProductAmountDialog v-if="store.selectedItem && store.selectedItem === store.currentItem">
          <template #activator="{ onClick }">
            <QBtn
              v-bind="buttonProps"
              icon="mdi-skip-next-outline"
              :disable="savingMovements"
              @click="onClick"
            >
              {{ t('Next') }}
            </QBtn>
          </template>
        </SelectionEditProductAmountDialog>
        <ConfirmsAction
          v-if="currentSelection.movements.length > 0"
          should-prompt
          :confirm-text="t('Complete Selection')"
          :cancel-text="t('No')"
          @confirmed="completeSelection"
        >
          <template #activator="{ prompt }">
            <QBtn
              v-bind="buttonProps"
              icon="mdi-check"
              @click="prompt"
            >
              {{ t('Complete Selection') }}
            </QBtn>
          </template>

          <template #title>
            {{ t('Complete Selection?') }}
          </template>
        </ConfirmsAction>
      </ButtonsRow>
    </Teleport>
    <BlurredInput
      v-if="!savingMovements"
      skip-input-if-barcode
      speak-digits
      @barcode="scanPackRef.scan($event)"
    />
    <VirtualKeyboard :show-comma-key="amountIsFractional" />
  </QPage>
</template>

<script setup lang="ts">

import BigLoading from '@/components/BigLoading.vue';
import ConfirmsAction from '@/components/ConfirmsAction.vue';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import GraphQLQueryScanField from '@/components/Mobile/GraphQLQueryScanField.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useErrorHandling from '@/composables/useErrorHandling';
import useOmniInput from '@/composables/useOmniInput';
import useSpeaker from '@/composables/useSpeaker';
import useWakeLockWhenMounted from '@/composables/useWakeLockWhenMounted';
import type { Cell, Container, SelectionOrderItem } from '@/graphql/types';
import allocateAmountForPackInList from '@/helpers/allocateAmountForPackInList';
import isContainer from '@/helpers/isContainer';
import navigateBack from '@/helpers/navigateBack';
import ROUTES from '@/router/routeNames';
import useSelectionStore from '@/stores/selection';
import type { FunctionValidationRule } from '@/types';
import type { SelectionCarouselItem } from '@/types/selection';
import ScanPack from '@/views/Mobile/Selection/ScanPack.vue';
import SelectionChangeCellButton from '@/views/Mobile/Selection/SelectionChangeCellButton.vue';
import SelectionDetailsForDashboard
  from '@/views/Mobile/Selection/SelectionDetailsForDashboard.vue';
import SelectionEditProductAmountDialog
  from '@/views/Mobile/Selection/SelectionEditProductAmountDialog.vue';
import SelectionHeader from '@/views/Mobile/Selection/SelectionHeader.vue';
import { gql } from '@urql/vue';
import { useEventBus } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import * as R from 'ramda';
import { computed, onBeforeMount, reactive, type Ref, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

const { t } = useI18n();

useWakeLockWhenMounted();

const store = useSelectionStore();

const route = useRoute();
const router = useRouter();

useBreadcrumbs(() => {
  if (!store.currentSelection) {
    return t('Selection');
  }

  if (R.uniqBy(so => so.shipment.id, store.currentSelection!.selectionOrders).length === 1) {
    return t('Selection by Shipment Order');
  }

  if (store.isClusterSelection) {
    return t('Cluster Selection');
  }

  return t('Group Selection');
});

const { primaryError, clearErrors, fillErrorsFromGraphQLError, PrimaryErrorBanner } = useErrorHandling();

const progress = reactive({
  initializing: false,
});

const { currentSelection, container, currentStorage, savingMovements } = storeToRefs(store);

watch(() => store.selectedItemIndex, () => {
  input.selectAll();
  clearErrors();
});

const storageQuery = gql`
  query GetStorageForSelection($barcode: String!) {
    storageByBarcode(barcode: $barcode) {
      id
      name
      storageCell {
        id
        name
      }
    }
   }
`;

const containerQuery = gql`
  query GetContainerForClusterSelectionOrder($barcode: String!) {
    containerByBarcode(barcode: $barcode) {
      id
      name
      barcode
    }
  }
`;

onBeforeMount(async function created(): Promise<void> {
  savingMovements.value = false;
  progress.initializing = true;

  if (currentSelection.value?.id !== route.params.id) {
    currentSelection.value = null;
    currentStorage.value   = null;
    container.value        = null;
  }

  if (route.params.id) {
    await store.loadSelection(route.params.id as string);
  } else {
    store.init();
  }

  progress.initializing = false;

  if (currentSelection.value === null) {
    await router.push({ name: ROUTES.SELECTION_DASHBOARD });
  }
});

const expectedStorage = computed(() => store.cellsToScan[0]);

const speaker = useSpeaker();

watch(
  () => !store.shouldScanContainer && !currentStorage.value && !!expectedStorage.value,
  expectingStorageScan => {
    if (expectingStorageScan) {
      speaker.speak(expectedStorage.value.name);
    }
  },
  { immediate: true },
);

function cellMatchesSelectionRule(cell: Cell): ReturnType<FunctionValidationRule> {
  if (!store.cells.some(c => c.id === cell.id)) {
    return t('Storage shall be that specified for scan');
  }
  return true;
}

const expectedContainer = computed(() => store.containers[0]);

function containerMatchesSelectionRule(storage: Container): ReturnType<FunctionValidationRule> {
  if (storage.id !== expectedContainer.value.id) {
    return t('Container shall matches to Selection');
  }
  return true;
}

const selectedProductAmountWasChanged = ref(false);
const commitAmountAfterChange = ref(false);

watch(() => store.selectedItemIndex, () => {
  selectedProductAmountWasChanged.value = false;
});

const canChangeAmount = computed(() =>
  // Можем менять количество в истории.
  store.selectedItemIndex < store.currentItemIndex
  // Можем менять количество, если оно не 0.
  || store.currentItem?.selectionOrderItem.selectedAmount > 0
  // Можем менять количество, если до этого его меняли
  // (предыдущее условие не подходит, так как сменить могли на 0).
  || selectedProductAmountWasChanged.value,
);

const amountIsFractional = computed(() => store.selectedItem?.selectionOrderItem.storageUnit.productPack.measurementUnit.isFractional ?? false);

const input = useOmniInput({
  replace: value => {
    // Не используем skip, т.к. нужна возможность сканирования ШК.
    if (savingMovements.value || !canChangeAmount.value) {
      return '';
    }
    return amountIsFractional.value
      ? value.replace(/,/g, '.').replace(/[^\d.]/g, '')
      : value.replace(/\D/g, '');
  },
});
const { BlurredInput, VirtualKeyboard, KeyboardToggleButton, value: inputValue, toggleCurrentKeyboard } = input;

watch(() => store.selectedItem, item => {
  if (!item) {
    return;
  }
  input.reset(String(item.selectionOrderItem.selectedAmount));
}, { immediate: true });

watch(inputValue, value => {
  const numberValue = Number(value);
  if (!Number.isNaN(numberValue)) {
    changeScannedAmount(numberValue);
  }
});

const scanPackRef: Ref<InstanceType<typeof ScanPack>> = ref(null!);

async function changeScannedAmount(newAmount: number): Promise<void> {
  const selectionOrderItem = store.selectedItem.selectionOrderItem;
  const diff = newAmount - selectionOrderItem.selectedAmount;

  if (savingMovements.value || diff === 0) {
    return;
  }

  if (diff > 0) {
    const excessAmount = await allocateAmountForPackInList(
      store.carouselItems,
      selectionOrderItem.storageUnit.productPack,
      diff,
      item => item.selectionOrderItem.storageUnit.productPack,
      item => item.selectionOrderItem.plannedAmount - item.selectionOrderItem.selectedAmount,
      (item, multiplier) => store.convertItemPack(item, selectionOrderItem.storageUnit.productPack, multiplier),
    );

    if (excessAmount > 0) {
      primaryError.value = t(
        'Excess product, only {needed} needed',
        { needed: store.selectedItem.selectionOrderItem.plannedAmount },
      );
      speaker.speak(primaryError.value);

      return;
    }
  }

  const existingMovement = store.itemMovement(store.selectedItem);

  const { error } = existingMovement
    ? (await (newAmount === 0
      ? store.deleteMovement(existingMovement.id)
      : store.updateMovementAmount({ movement: existingMovement, amount: newAmount })))
    : (await store.createMovement({
      storageFromId: store.currentStorage!.id,
      containerToId: store.selectedItem.selectionOrder.container.id,
      storageUnitId: store.selectedItem.selectionOrderItem.storageUnit.id,
      amount: newAmount,
    }));

  if (error) {
    fillErrorsFromGraphQLError(error);
    speaker.speak(primaryError.value);
    return;
  }
  clearErrors();

  store.selectedItem.selectionOrderItem.selectedAmount = newAmount;

  if (store.currentStorageFullySelected) {
    store.currentStorage = null;
  }

  // Если меняли количество не для текущего товара,
  // то возможно потребуется добавить/удалить слайд в плане.
  if (store.selectedItemIndex !== store.currentItemIndex) {
    amountDirty.value = true;
  }

  // Если для текущего товара ввели нужное количество, переходим к следующему,
  // перед этим произнеся имя контейнера в случае кластерного отбора
  if (newAmount === store.currentItem.selectionOrderItem.plannedAmount
    && store.selectedItemIndex === store.currentItemIndex
  ) {
    if (store.containers.length > 1) {
      speaker.speak(store.currentItem.selectionOrder.container.name);
    }
    // По сути это то же самое, что отсканировать последнюю единицу товара,
    // поэтому используем тот же функционал
    store.adjustScannedItemPosition(store.carouselItems[store.currentItemIndex]);
  }
  selectedProductAmountWasChanged.value = true;

  if (commitAmountAfterChange.value) {
    commitAmountAfterChange.value = false;
    commitAmountIfDirty(store.selectedItem);
  }
}

const amountDirty = ref(false);

watch(() => store.selectedItem, (_, prevItem) => {
  commitAmountIfDirty(prevItem);
});

function commitAmountIfDirty(item: SelectionCarouselItem) {
  if (amountDirty.value) {
    store.handleProductAmountChange(item);
  }
  amountDirty.value = false;
}

function handleBeforeProductScan() {
  commitAmountIfDirty(store.selectedItem);
}

async function cancelSelection(): Promise<void> {
  await store.cancelSelection(currentSelection.value!);
  await reset();
}

async function reset() {
  await navigateBack({ name: ROUTES.SELECTION_DASHBOARD });
  currentStorage.value = null;
  currentSelection.value = null;
  container.value = null;
  savingMovements.value = false;
  clearErrors();
}

watch(() => store.fullySelected, async fullySelected => {
  if (fullySelected && currentSelection.value) {
    await completeSelection();
  }
});

async function completeSelection(): Promise<void> {
  await store.completeSelection();
  speaker.speak(t('Selection Completed'));
  await reset();
}

const selectionPlanOpen = ref(false);

async function handleDeleteItem() {
  // В отличие от редактирования с клавиатуры, удаление должно отразиться на слайдах сразу же,
  // если удален был НЕ текущий слайд.
  commitAmountAfterChange.value = true;
  // Если удален был НЕ текущий слайд,
  // то в changeScannedAmount dirty станет true
  // и изменение будет отражено на слайдах (commitAmountIfDirty).
  input.reset('0');
}

function closeSelectionPlan() {
  selectionPlanOpen.value = false;
  if (selectedPlanItem.value) {
    store.selectSelectionOrderItemOnCarousel(selectedPlanItem.value);
  }
}

const selectedPlanItem = ref<SelectionOrderItem | null>(null);

const { on: onCloseDialogs } = useEventBus('close-dialogs');

onCloseDialogs(() => {
  selectionPlanOpen.value = false;
});

function handleProductScan() {
  toggleCurrentKeyboard(false);
  input.reset(String(store.selectedItem.selectionOrderItem.selectedAmount));
  clearErrors();
}

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/selection.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml"></i18n>

<i18n lang="yaml">
ru:
  Storage shall be that specified for scan: Место хранения должно соответствовать указанному
  Container shall matches to Selection: Контейнер должен соответствовать отбору

en:
  Storage shall be that specified for scan: Storage shall be that specified for scan
  Container shall matches to Selection: Container shall matches to Selection

</i18n>
