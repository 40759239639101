<template>
  <div v-if="expectedStorage">
    <h5 class="q-ma-none">
      {{ expectedStorage?.name }}
    </h5>
  </div>

  <GraphQLQueryScanField
    :query="scanQuery"
    :variables="{ transferId: store.transfer?.id }"
    :rules="scanRules"
    :hint="hint"
    :placeholder="t('Storage')"
    :not-found-message="t('Storage not found')"
    append-icon="mdi-inbox-arrow-down"
    @found="handleFoundStorageTo"
    @scan="handleScanStorageTo"
  />
</template>

<script setup lang="ts">

import GraphQLQueryScanField from '@/components/Mobile/GraphQLQueryScanField.vue';
import useSpeaker from '@/composables/useSpeaker';
import StockForTransfer from '@/graphql/fragments/StockForTransfer';
import StorageForTransfer from '@/graphql/fragments/StorageForTransfer';
import StorageUnitForTransfer from '@/graphql/fragments/StorageUnitForTransfer';
import type { Cell, Container, Storage, Stock } from '@/graphql/types';
import { ContainerKindEnum } from '@/graphql/types';
import useTransferProcessStore from '@/stores/transferProcess';
import { gql } from '@urql/vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { FunctionValidationRule } from '@/types';

const store = useTransferProcessStore();

const { t } = useI18n();

const emit = defineEmits<{
  (e: 'scan', storage: Storage): void;
}>();

const props = defineProps<{
  expectedStorage: Storage | null;
}>();

const hint = computed(() => {
  if (props.expectedStorage) {
    return t('Scan the above specified Storage');
  }
  return t('Scan Second Storage');
});

const scanQuery = gql`
  ${StorageForTransfer}
  ${StorageUnitForTransfer}
  ${StockForTransfer}
  query GetSecondStorageByBarcodeForTransfer($transferId: ID!, $barcode: String!) {
    storageByBarcode(barcode: $barcode) {
      ...StorageForTransfer
    }
    stocksByStorageBarcode(transferId: $transferId, barcode: $barcode) {
      ...StockForTransfer
    }
  }
`;

function handleFoundStorageTo(data: {
  storageByBarcode: Storage | null;
  stocksByStorageBarcode: Stock[];
}): void {
  if (!data.storageByBarcode) {
    return;
  }
  store.stocksInStorageTo = data.stocksByStorageBarcode;
}

const speaker = useSpeaker();

const scanRules = [
  notEqualToStorageFromRule,
  storageMeetsRequiredRule,
  storageToCellTypeRule,
  storageToContainerTypeRule,
  storageFromCellTypeRule,
  storageFromContainerTypeRule,
];

async function handleScanStorageTo(storage: Storage) {
  speaker.speak(t('To {name}', { name: storage.name }));
  store.storageTo = storage;
  store.updateTransferState();
  store.availableStorages.push(storage);
  emit('scan', storage);
}

function notEqualToStorageFromRule(storageTo: Storage) {
  if (storageTo.id === store.storageFrom!.id) {
    return t('Storages should be different');
  }

  return true;
}

function storageIsContainerWithOrders(storage: Container | Cell): boolean {
  return storage.__typename === 'Container'
      && (storage as Container).kind === ContainerKindEnum.ORDERS;
}

// noinspection LocalVariableNamingConventionJS
const isTransferFromContainerWithOrders = computed(() => {
  return storageIsContainerWithOrders(store.storageFrom!);
});

function storageToCellTypeRule(storageTo: Container | Cell) {
  if (isTransferFromContainerWithOrders.value && storageTo.__typename === 'Cell') {
    return t('Cannot transfer from container with orders to cell');
  }

  return true;
}

function storageToContainerTypeRule(storageTo: Container | Cell) {
  const isStorageToNotEmpty = store.stocksInStorageTo.some(s => s.amount > 0);

  // noinspection OverlyComplexBooleanExpressionJS
  if (
    isTransferFromContainerWithOrders.value
      && storageTo.__typename === 'Container'
      && (storageTo as Container).kind === ContainerKindEnum.PRODUCTS
      && isStorageToNotEmpty
  ) {
    return t('Cannot transfer to non-empty container with products');
  }

  return true;
}

function storageFromCellTypeRule(storageTo: Container | Cell) {
  if (
      store.storageFrom!.__typename === 'Cell'
      && storageIsContainerWithOrders(storageTo)
  ) {
    return t('Cannot transfer from cell to container with orders');
  }

  return true;
}

function storageFromContainerTypeRule(storageTo: Container | Cell) {
  if (
      store.storageFrom!.__typename === 'Container'
      && (store.storageFrom as Container).kind === ContainerKindEnum.PRODUCTS
      && storageIsContainerWithOrders(storageTo)
  ) {
    return t('Cannot transfer from container with products to container with orders');
  }

  return true;
}

function storageMeetsRequiredRule(storage: Storage): ReturnType<FunctionValidationRule> {
  if (props.expectedStorage && props.expectedStorage.id !== storage.id) {
    return t('The storage should match specified');
  }

  return true;
}


</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml"></i18n>

<i18n lang="yaml">
ru:
  Cannot transfer from container with orders to cell: >
    Нельзя переместить из контейнера с типом Заказ/Заказы в ячейку
  Cannot transfer from container with products to container with orders:
    Нельзя переместить из контейнера с типом Товары в контейнер с типом Заказы
  Cannot transfer from cell to container with orders:
    Нельзя переместить из ячейки в контейнер с типом Заказы
  Cannot transfer to non-empty container with products: >
    Нельзя перемещать в непустой контейнер с товарами
  Scan Second Storage: Сканируйте второе место хранения
  Scan Storage from specified Cells Area: Сканируете место хранения из указанной зоны
  Storages should be different: Ячейки должны быть разными
  The storage should match specified Area Kind: Место хранения должно соответствовать указанному типу зоны


en:
  Cannot transfer from container with orders to cell: >
    Cannot transfer from container with orders to cell
  Cannot transfer from container with products to container with orders:
    Cannot transfer from container with products to container with orders
  Cannot transfer from cell to container with orders: >
    Cannot transfer from cell to container with orders
  Cannot transfer to non-empty container with products: >
    Cannot transfer to non-empty container with products
  Scan Second Storage: Scan Second Storage
  Scan Storage from specified Cells Area: Scan Storage from specified Cells Area
  Storages should be different: Storages should be different
  The storage should match specified Area Kind: The storage should match specified Area Kind
</i18n>
