<template>
  <VirtualKeyboardPolicyContext #="{ policy, toggle, shouldShowToggle }">
    <QInput
      ref="inputEl"
      v-model="inputValue"
      v-bind="$attrs"
      :rules="[dateRule]"
      :mask="readonly ? undefined : INPUT_MASK"
      :virtualkeyboardpolicy="policy"
      fill-mask
      unmasked-value
      lazy-rules
      :readonly="readonly"
      @focus="inputEl.select()"
    >
      <template
        v-if="!readonly"
        #append
      >
        <QIcon
          name="mdi-calendar"
          class="cursor-pointer"
        >
          <QPopupProxy v-model="isOpen">
            <QDate
              v-model="pickerValue"
              :mask="OUTPUT_FORMAT"
            />
          </QPopupProxy>
        </QIcon>
      </template>
    </QInput>
  </VirtualKeyboardPolicyContext>
</template>

<script setup lang="ts">

import { VirtualKeyboardPolicyContext } from '@/composables/useVirtualKeyboardPolicy';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { QInput } from 'quasar';
import dayjs from 'dayjs';

const { t } = useI18n();

const READONLY_DISPLAY_FORMAT = 'DD.MM.YYYY';
const EDITABLE_DISPLAY_FORMAT = 'DDMMYYYY';
const OUTPUT_FORMAT = 'YYYY-MM-DD';
const INPUT_MASK ='##.##.####';

const { formatDate } = useLocalizedFormatters();

const props = defineProps<{
  modelValue: string | null;
  readonly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null): void;
}>();

const inputEl = ref();

const inputValue = ref<string | null>(null);

const isOpen = ref(false);

const displayFormat = computed(() => props.readonly ? READONLY_DISPLAY_FORMAT : EDITABLE_DISPLAY_FORMAT);

watch(() => props.modelValue, value => {
  inputValue.value = formatDate(value, displayFormat.value);
}, { immediate: true });

watch(inputValue, value => {
  if (dateRule(value) === true) {
    emit('update:modelValue', value ? dayjs(value, displayFormat.value, true).format(OUTPUT_FORMAT) : null);
  }
});

function dateRule(value: string | null) {
  if (!value || dayjs(value, displayFormat.value, true).isValid()) {
    return true as const;
  }
  return t('validations.date');
}

const pickerValue = computed<string>({
  get() {
    return props.modelValue || '';
  },

  set(value) {
    emit('update:modelValue', value || null);
    isOpen.value = false;
  },
});

</script>
