<template>
  <BigLoading v-if="store.initializing" />
  <QPage
    v-else
    class="column no-wrap scroll-y"
  >
    <TransferTaskDialog
      v-model="showingTask"
      :transfer="store.transfer!"
    />
    <TransferHeader />
    <TransferScanFirstStorage
      v-if="!store.storageFrom?.storageCell"
      :expected-storage="expectedStorageFrom"
      :keyboard-disabled="carouselInputDisabled"
      class="col"
      @cancel="cancel"
      @show-task="showingTask = true"
      @show-history="historyDialog = true"
    />
    <TransferScanSecondStorage
      v-else-if="!store.storageTo"
      :expected-storage="expectedStorageTo"
      :keyboard-disabled="carouselInputDisabled"
      class="col"
      @cancel="cancel"
      @show-task="showingTask = true"
      @show-history="historyDialog = true"
    />
    <TransferScanStorable
      v-else
      v-model:carousel-input-disabled="carouselInputDisabled"
      class="col full-width"
      @cancel="cancel"
      @show-task="showingTask = true"
      @show-history="historyDialog = true"
    />
    <BaseAlert
      v-if="primaryError"
      type="error"
    >
      {{ primaryError }}
    </BaseAlert>
    <MaximizedDialog
      v-model="historyDialog"
      transition-show="slide-up"
      transition-hide="slide-down"
      :title="breadcrumbs.join(' / ')"
      @close="historyDialog = false"
    >
      <TransferDetails
        :transfer="store.transfer!"
        hide-header
      />
    </MaximizedDialog>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BigLoading from '@/components/BigLoading.vue';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useErrorHandling from '@/composables/useErrorHandling';
import useWakeLockWhenMounted from '@/composables/useWakeLockWhenMounted';
import ROUTES from '@/router/routeNames';
import useTransferProcessStore from '@/stores/transferProcess';
import TransferDetails from '@/views/Mobile/Transfer/TransferDetails.vue';
import TransferHeader from '@/views/Mobile/Transfer/TransferHeader.vue';
import TransferScanFirstStorage from '@/views/Mobile/Transfer/TransferScanFirstStorage.vue';
import TransferScanSecondStorage from '@/views/Mobile/Transfer/TransferScanSecondStorage.vue';
import TransferScanStorable from '@/views/Mobile/Transfer/TransferScanStorable.vue';
import TransferTaskDialog from '@/views/Mobile/Transfer/TransferTaskDialog.vue';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

useWakeLockWhenMounted();

const store = useTransferProcessStore();

const { t } = useI18n();

const { breadcrumbs } = useBreadcrumbs(computed(() => store.transfer?.task
  ? t('operationType.Restocking')
  : t('operationType.Transfer')
));

const {
  primaryError,
  fillErrorsFromGraphQLError,
  clearErrors,
} = useErrorHandling();

const carouselInputDisabled = ref<boolean>(false);

const router = useRouter();

const props = withDefaults(defineProps<{
  transferId?: string | null;
}>(), {
  transferId: null,
});

const showingTask = ref(false);

const historyDialog = ref(false);

onBeforeMount(async () => {
  await store.init(props.transferId);
});

watch(() => store.transfer, transfer => {
  if (!props.transferId && transfer?.id) {
    router.replace({ name: ROUTES.TRANSFER_PROCESS, params: { transferId: transfer.id } });
  }
});

const expectedStorageFrom = computed(
  () => store.currentItem?.storageFrom ?? null
);

const expectedStorageTo = computed(
  () => store.currentItem?.storageTo ?? null
);

async function cancel() {
  clearErrors();

  const { error } = await store.cancelTransfer();

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  store.clearStateForTransfer();

  store.transfer = null;
  store.storageFrom = null;
  store.storageTo = null;

  await router.push({ name: ROUTES.TRANSFER_DASHBOARD  });
}

</script>
