<template>
  <QItem
    class="list-item-default-height"
    :disable="disableable && !order.hasStockForSelection"
  >
    <slot name="left" />

    <QItemSection class="dense-labels">
      <QItemLabel class="row no-wrap">
        <ScrollBack
          :delay="500"
          class="text-no-wrap"
        >
          {{ order.externalId }}
          <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
          <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
          |
          {{ formatDate(order.createdAt, FORMATS.DATETIME_NO_YEAR) }}
        </ScrollBack>

        <QSpace />

        <span class="text-right q-ml-sm">
          <QChip
            dense
            class="q-ma-none"
            :color="forShipmentState(order)"
            text-color="black"
          >
            {{ t('shipmentState.' + order.state) }}
          </QChip>
        </span>
      </QItemLabel>
      <QItemLabel class="row justify-between">
        <span>
          {{ order.carrier?.name }}
        </span>
        <span v-if="order.plannedShipmentDate">
          <QIcon
            name="mdi-truck-cargo-container"
            class="q-mr-xs"
          />
          {{ formatDate(order.plannedShipmentDate, FORMATS.DATE) }}
        </span>
      </QItemLabel>
      <QItemLabel class="row justify-between">
        <span>
          {{ t('{n} products', order.items.filter(item => item.amount !== 0).length) }}
          /
          {{ formatWeight(calculateWeight(order)) }}
          /
          {{ formatVolume(calculateVolumeForShipment(order)) }}
        </span>
        <span>
          {{ order.counterparty?.name }}
        </span>
        <span class="text-right q-ml-sm">
          <QChip
            v-if="disableable && order.needsRestocking"
            dense
            class="q-ma-none"
            color="blue"
          >
            {{ t('Rest.') }}
          </QChip>
        </span>
      </QItemLabel>
    </QItemSection>
  </QItem>
</template>

<script setup lang="ts">

import ScrollBack from '@/components/ScrollBack.vue';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { Shipment } from '@/graphql/types';
import { forShipmentState } from '@/helpers/badgeColors';
import { calculateVolumeForShipment, calculateWeight } from '@/helpers/shipments';
import { useI18n } from 'vue-i18n';

defineProps<{
  order: Shipment;
  disableable?: boolean;
}>();

const { t } = useI18n();

const { formatDate, formatVolume, formatWeight } = useLocalizedFormatters();

</script>

<i18n lang="yaml" src="../plugins/i18n/sharedMessages/counts.yaml"></i18n>

<i18n lang="yaml">
ru:
  Rest.: Пополн.

en:
  Rest.: Rest.

</i18n>

<style scoped lang="scss">

@import 'quasar/src/css/variables';

</style>
