<template>
  <MaximizedDialog
    v-model="isOpen"
    :title="t('New Barcode')"
    transition-show="slide-up"
    transition-hide="slide-down"
    @close="isOpen = false"
  >
    <div
      v-if="otherPacksWithBarcode.length > 0"
      class="col column justify-center"
    >
      <BaseAlert type="warning">
        {{ t('Barcode belongs to another product, do you want to add it?') }}
      </BaseAlert>
      <QList>
        <ProductListItem
          v-for="pack in otherPacksWithBarcode"
          :key="pack.product.id"
          :product="pack.product"
        />
      </QList>
    </div>
    <div
      v-else
      class="col column justify-center"
    >
      <PrimaryErrorBanner />
      <QCardSection>
        <div class="row no-wrap">
          <QInput
            v-model="barcodeGroup"
            :label="t('Type')"
            :disable="addingBarcode"
            dense
            style="width: 64px;"
          />
          <VirtualKeyboardPolicyContext #="{ policy, toggle, shouldShowToggle }">
            <GraphQLQueryScanField
              ref="barcodeField"
              :query="scanQuery"
              :label="t('New Barcode')"
              :virtualkeyboardpolicy="policy"
              :loading="addingBarcode"
              :barcode-rules="[minLength(BARCODE_MIN_LENGTH)]"
              :rules="[isUniqueRule]"
              class="col"
              @scan="handleScan"
              @update:barcode="barcode = $event"
            >
              <template #prepend>
                <QIcon name="mdi-barcode" />
              </template>
              <template
                v-if="shouldShowToggle"
                #append
              >
                <QBtn
                  flat
                  round
                  icon="mdi-keyboard"
                  @click="toggle(); barcodeField.focus();"
                />
              </template>
            </GraphQLQueryScanField>
          </VirtualKeyboardPolicyContext>
        </div>
      </QCardSection>
    </div>

    <template #bottom>
      <ButtonsRow
        v-slot="{ buttonProps }"
        v2
      >
        <QBtn
          v-bind="buttonProps"
          icon="mdi-dice-3"
          :loading="generatingBarcode"
          @click="generateBarcode()"
        >
          {{ t('Random barcode') }}
        </QBtn>
        <QBtn
          v-if="otherPacksWithBarcode.length > 0"
          v-bind="buttonProps"
          icon="mdi-plus"
          @click="confirmAddNonUniqueBarcode"
        >
          {{ t('Yes') }}
        </QBtn>
        <QBtn
          v-if="otherPacksWithBarcode.length > 0"
          v-bind="buttonProps"
          icon="mdi-cancel"
          @click="otherPacksWithBarcode = []"
        >
          {{ t('No') }}
        </QBtn>
      </ButtonsRow>
    </template>
  </MaximizedDialog>

  <slot @open="isOpen = true" />

  <BlurredInput @barcode="handleBarcodeScan" />
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import GraphQLQueryScanField from '@/components/Mobile/GraphQLQueryScanField.vue';
import ProductListItem from '@/components/ProductListItem.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useOmniInput from '@/composables/useOmniInput';
import useValidationRules from '@/composables/useValidationRules';
import { VirtualKeyboardPolicyContext } from '@/composables/useVirtualKeyboardPolicy';
import { BARCODE_MIN_LENGTH } from '@/constants';
import type {
  MutationAddProductPackBarcodeArgs,
  MutationGenerateProductPackBarcodeArgs,
  ProductPack,
  ProductPackWithAmount,
} from '@/graphql/types';
import { gql, useClientHandle, useMutation } from '@urql/vue';
import type { QInput as QInputType } from 'quasar';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { fillErrorsFromGraphQLError, PrimaryErrorBanner, clearErrors } = useErrorHandling();

const { minLength } = useValidationRules();

const props = defineProps<{
  productPack: ProductPack;
}>();

const emit = defineEmits<{
  (e: 'save', pack: Pick<ProductPack, 'lastScannedBarcode' | 'barcodes'>): void;
  (e: 'goto-product', id: string): void;
}>();

const isOpen = ref(false);

const barcodeGroup = ref('');

const barcode = ref('');

const barcodeField = ref<InstanceType<typeof QInputType>>(null!);

async function addEnteredBarcode() {
  clearErrors();

  const { data, error } = await doAddBarcode({
    productPackId: props.productPack.id,
    group:         barcodeGroup.value,
    barcode:       barcode.value,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  emit('save', data!.pack);

  barcodeGroup.value = '';
  barcode.value = '';

  isOpen.value = false;
}

async function generateBarcode(): Promise<void> {
  const { data } = await doGenerateBarcode({ id: props.productPack.id });

  if (data) {
    emit('save', {
      ...props.productPack,
      ...data.pack,
    });
  }

  isOpen.value = false;
}

const {
  executeMutation: doGenerateBarcode,
  fetching: generatingBarcode,
} = useMutation<{ pack: ProductPack }, MutationGenerateProductPackBarcodeArgs>(
  gql`
    mutation GenerateProductPackBarcode($id: ID!) {
      pack: generateProductPackBarcode(id: $id) {
        lastScannedBarcode {
          barcode
          group
          isGenerated
        }
        barcodes {
          barcode
          group
          isGenerated
        }
      }
    }
  `,
);

const {
  executeMutation: doAddBarcode,
  fetching: addingBarcode,
} = useMutation<{ pack: ProductPack }, MutationAddProductPackBarcodeArgs>(
  gql`
    mutation AddProductPackBarcode($productPackId: ID!, $group: String, $barcode: String!) {
      pack: addProductPackBarcode(productPackId: $productPackId, group: $group, barcode: $barcode) {
        lastScannedBarcode {
          barcode
          group
          isGenerated
        }
        barcodes {
          barcode
          group
          isGenerated
        }
      }
    }
  `,
);

const { client: urql } = useClientHandle();

const { BlurredInput } = useOmniInput({
  skip: isOpen,
});

async function handleBarcodeScan(value: string) {
  const { data } = await urql.query<{ packWithAmount: ProductPackWithAmount | null }>(gql`
    query ProductPackByBarcodeToOpenFromMobileCard($barcode: String!) {
       packWithAmount: productPackByBarcode(barcode: $barcode) { productPack {
        id product { id }
      }}
    }
  `, { barcode: value });

  if (data!.packWithAmount?.productPack.product) {
    emit('goto-product', data!.packWithAmount?.productPack.product.id);
  }
}

const scanQuery = gql`
  query ScanProductBarcodeForEditCard($barcode: String!) {
    entitiesByBarcode(barcode: $barcode) {
      ...on Storage { __typename id }
      ...on ProductPack { __typename id product {
        id
        sku
        name
        photos { id url(size: SMALL) }
      }  }
    }
  }
`;

function isUniqueRule(entities: (Storage | ProductPack)[]) {
  // Не даем добавлять ШК, занятые другими сущностями.
  if (entities.some(e => e.__typename !== 'ProductPack'
      // Не даем добавлять ШК, который уже есть у этого же товара.
      || e.product.id === props.productPack.product.id)
  ) {
    return t('Barcode exists');
  }

  return true;
}

const otherPacksWithBarcode = ref<ProductPack[]>([]);

function handleScan(entities: ProductPack[] | null, newBarcode: string) {
  barcode.value = newBarcode;
  if (!entities) {
    return;
  }
  if (entities.length > 0) {
    otherPacksWithBarcode.value = entities;
    return;
  }

  addEnteredBarcode();
}

function confirmAddNonUniqueBarcode() {
  otherPacksWithBarcode.value = [];
  addEnteredBarcode();
}

</script>

<i18n lang="yaml">
ru:
  New Barcode: Новый штрихкод
  Random barcode: Случайный штрихкод
  Barcode exists: Штрихкод уже существует
en:
  New Barcode: New Barcode
  Random barcode: Random barcode
  Barcode exists: Barcode exists
</i18n>
